@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: block;
    border-radius: 10px;
    color: $color-green;

    .icon {
        display: block;
        width: 50px;
        height: auto;
        padding: 5px;
        filter: drop-shadow(0 0 6px $color-black) drop-shadow(1px 1px 4px $color-black)
            drop-shadow(-1px -1px 4px $color-black);
        transition: transform 0.2s ease;
    }

    &:focus {
        border-radius: 50%;
        @extend %focus;
    }

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}
