@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    color: $color-green;
    text-align: center;

    .inner {
        position: relative;
        height: calc(100% - $controls-height);

        .withReader {
            display: none;
        }
    }

    .title {
        padding-top: 40px;
        font-size: $font-size-normal;
        font-style: italic;
        @media #{$mq-medium-screen} {
            padding-top: 60px;
            font-size: $font-size-medium;
        }
    }

    .notItalic {
        font-style: normal;
    }

    .subtitle {
        font-family: $font-controls;
        font-size: small;
    }

    .navigation {
        position: absolute;
        bottom: 0;

        .showReader {
            display: none;
        }
    }

    .backContainer {
        position: absolute;
        right: 15px;
        bottom: 0;
        padding: 15px;
    }

    .backMobileButton {
        z-index: 4;
        width: 40px;

        @media #{$mq-large-screen} {
            bottom: 0.8em;
        }
    }

    .closedCaptions {
        position: absolute;
        top: 50%;
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
}
