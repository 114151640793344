@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: flex;
    align-items: center;

    // justify-content: center;
    padding: 0.75em 2em;
    background: transparent;
    border-radius: 10px;
    color: $color-green;
    text-align: left;
    text-decoration: none;

    @media #{$mq-medium-screen} {
        // justify-content: flex-start;
    }

    .iconContainer {
        flex-shrink: 0;
    }

    .icon {
        width: auto;
        min-width: 2em;
        height: 2em;
        transition: transform 0.2s ease;
    }

    .children {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;

        @media #{$mq-medium-screen} {
            position: unset;
            overflow: visible;
            width: auto;
            height: auto;
        }
    }

    &:focus {
        @extend %focus;
    }

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }

    &:focus,
    &:hover {
        .children {
            position: fixed;
            z-index: 1;
            top: 200px;
            left: 50%;
            width: 100%;
            height: auto;
            text-align: center;
            transform: translate(-50%, -50%);

            @media #{$mq-medium-screen} {
                position: unset;
                overflow: visible;
                width: auto;
                height: auto;
                text-align: left;
                transform: translate(0);
            }
        }
    }

    &:focus{
        .children {
            top: 230px;
        }
    }
}
