@import '../commons/variables';
@import '../commons/placeholders';

.container {
    min-height: 100vh;
    background-color: $color-green;
    background-image: url('../../images/credits_background.jpg');
    background-size: cover;
    color: $color-black;

    .logo {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 120px;
        padding: 10px;
        border-radius: 0 0 10px;
        transition: transform 0.2s ease;

        @media #{$mq-medium-screen} {
            width: 200px;
            padding: 20px;
        }

        &:focus {
            @extend %focus;
        }

        &:focus,
        &:hover {
            transform: scale(1.1);
        }
    }

    .logoShadow {
        position: absolute;
        z-index: -1;
        top: 0%;
        left: -10%;
        width: 110%;
        height: 80%;
        background-color: $color-black;
        border-radius: 50%;
        box-shadow: 0 0 30px 30px $color-black;
        filter: blur(30px);
        opacity: 0.9;
    }

    .logoIcon {
        position: relative;
        z-index: 1;
    }

    .title {
        z-index: 2;
        padding-bottom: 1em;
        font-size: $font-size-large;
        text-align: center;

        @media #{$mq-medium-screen} {
            font-size: $font-size-extra-large;
        }
    }

    .inner {
        position: relative;
        z-index: 0;
        width: 100%;
        max-width: 800px;
        padding: 10px 30px 30px;
        margin: auto;
        @media #{$mq-medium-screen} {
            padding: 10px;
        }
    }

    .shadow {
        position: fixed;
        z-index: -1;
        top: 50%;
        left: 50%;
        box-shadow: 0 0 20vw 40vw $color-green;
    }

    .section {
        margin-bottom: 30px;
        font-size: $font-size-normal;
        @media #{$mq-medium-screen} {
            font-size: $font-size-medium;
        }
    }

    .credits {
        columns: 1;
        text-align: center;
        @media #{$mq-medium-screen} {
            column-gap: 20%;
            columns: 2;
            text-align: left;
        }
    }

    .listSection {
        padding-top: 20px;

        &:first-of-type {
            padding-top: 0;
        }
    }

    .heading {
        font-size: $font-size-medium;
        font-weight: bold;
    }

    .externalLink {
        color: $color-black;
        text-decoration: none;

        &:focus,
        &:hover {
            font-style: italic;
            font-weight: 700;
        }

        &:focus {
            box-shadow: 0 0 12px $color-black;
            outline: 3px solid $color-black;
        }
    }

    .externalIcon {
        width: 0.75em;
        height: auto;
        padding-bottom: 5px;
        padding-left: 5px;
    }

    .subcategory {
        padding-top: 15px;
        border-top: 1px solid black;
        break-inside: avoid-column;
    }

    .noBorder {
        padding-top: 0;
        border: none;
    }

    .label {
        font-size: $font-size-small;
        font-style: italic;
        @media #{$mq-medium-screen} {
            font-size: $font-size-normal;
        }
    }

    .value {
        padding-bottom: 15px;
    }

    .subheader {
        border-bottom: 1px solid black;
        margin-bottom: 20px;
        font-size: $font-size-medium;
        font-weight: bold;

        // font-style: italic;
        text-align: center;
    }

    .cca {
        width: 100%;
        height: auto;
    }

    .partnersImages {
        display: flex;
        flex-direction: row;
    }

    .logoContainer {
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
    }

    .acknowledgement {
        font-size: $font-size-small;
        font-style: italic;
    }

    .acknowledgements {
        text-align: center;
        @media #{$mq-medium-screen} {
            padding: 0 140px;
        }
    }

    .original {
        font-size: $font-size-small;
        text-align: center;
    }

    .restartContainer {
        display: flex;
        justify-content: center;
    }
}
