@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    .textureBackground,
    .noiseBackground {
        @extend %fullscreen;

        z-index: 4;
        background-size: cover;
        pointer-events: none;
    }

    .textureBackground {
        background-image: url('../../images/texture_background.png');
        mix-blend-mode: screen;
    }

    .noiseBackground {
        background-image: url('../../images/noise_background_light.png');
        mix-blend-mode: soft-light;
    }

    .inner {
        @extend %fullscreen;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .logoContainer {
        position: relative;
        z-index: 3;
        width: 90%;
        padding: 50px 10px 0;
        margin: 0 auto;
        pointer-events: none;
    }

    .logo {
        display: block;
        width: 100%;
        height: auto;
    }

    .circlesContainer {
        @extend %fullscreen;
    }

    .circles {
        @extend %fullscreen;
    }

    .description {
        position: relative;
        z-index: 2;
        padding: 0 1em;
        padding-bottom: 100px;
        color: $color-green;
        font-size: 1.5rem;
        font-weight: normal;
        text-align: center;

        strong {
            font-weight: bold;
        }
    }

    .instructions {
        position: absolute;
        z-index: 2;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .buttonContainer {
        position: absolute;
        z-index: 2;
        bottom: 70px;
        left: 50%;
        font-size: $font-size-normal;
        text-align: center;
        transform: translate(-50%, 0);
    }

    @media #{$mq-medium-screen} {
        .logoContainer {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            padding: 0 20px;
        }

        .logo {
            max-height: 80vh;
            margin: auto;
            transform: translate(0, -10%);
        }

        .description {
            position: absolute;
            z-index: 2;
            top: 50%;
            width: 100%;
            padding-bottom: 0;
            font-size: 3rem;
            transform: translate(0, -50%);
        }

        .buttonContainer {
            bottom: 0;
        }

        .instructions {
            bottom: 20px;
            right: 20px;
            left: auto;
            transform: none;
        }
    }
}
