@import '../commons/variables';

.container {
    position: relative;
    text-align: center;
    user-select: none;

    .captions {
        display: inline-block;
        padding: 20px;
        margin: 0 auto;
        background-color: #000;
        font-size: $font-size-medium;
        word-break: break-word;

        @media #{$mq-medium-screen} {
            font-size: $font-size-large;
        }
    }
}
