@import 'commons/variables';
@import 'commons/fonts';
@import 'commons/reset';

html,
body {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

body {
    background: $color-black;
    color: $color-white;
    font-family: $font-text;
}
