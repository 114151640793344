@import '../commons/variables';
@import '../commons/placeholders';

.container {
    min-height: 100vh;
    background-color: $color-white;
    background-image: url('../../images/about_background.jpg');
    background-size: cover;
    color: $color-black;
    font-size: $font-size-normal;
    @media #{$mq-medium-screen} {
        font-size: $font-size-medium;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }

    ul {
        margin-bottom: 2em;
    }

    ul,
    li {
        list-style: circle;
    }

    li {
        margin-bottom: 0.5em;
        margin-left: 1em;
    }

    .logo {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 120px;
        padding: 10px;
        border-radius: 0 0 10px;
        transition: transform 0.2s ease;

        @media #{$mq-medium-screen} {
            width: 200px;
            padding: 20px;
        }

        &:focus {
            @extend %focus;
        }

        &:focus,
        &:hover {
            transform: scale(1.1);
        }
    }

    .logoShadow {
        position: absolute;
        z-index: -1;
        top: 0%;
        left: -10%;
        width: 110%;
        height: 80%;
        background-color: $color-black;
        border-radius: 50%;
        box-shadow: 0 0 30px 30px $color-black;
        filter: blur(30px);
        opacity: 0.9;
    }

    // .logoShadow {
    //     position: absolute;
    //     z-index: -1;
    //     top: 50%;
    //     left: 50%;
    //     box-shadow: 0 0 80px 50px $color-black;
    //     transform: scale(1.5, 0.5);

    //     @media #{$mq-medium-screen} {
    //         box-shadow: 0 0 120px 100px $color-black;
    //     }
    // }

    .logoIcon {
        position: relative;
        z-index: 1;
    }

    .title,
    .sectionHeader {
        padding-bottom: 1.5em;
        font-size: $font-size-large;
        text-align: center;

        @media #{$mq-medium-screen} {
            font-size: $font-size-extra-large;
        }
    }

    .sectionHeader {
        padding-top: 1.5em;
    }

    .inner {
        position: relative;
        z-index: 1;
        display: flex;
        width: 80%;
        max-width: 800px;
        flex-direction: column;
        padding: 10px 20px 60px;
        margin: auto;
    }

    .shadow {
        position: fixed;
        z-index: -1;
        top: 50%;
        left: 50%;
        box-shadow: 0 0 20vh 50vh $color-white;
        opacity: 0.8;
        transform: scale(0.75, 1);

        @media #{$mq-medium-screen} {
            box-shadow: 0 0 20vw 40vw $color-white;
            transform: scale(1);
        }
    }

    .text {
        margin: 0 auto;
        font-size: $font-size-normal;

        p {
            padding-bottom: 1em;
        }

        @media #{$mq-medium-screen} {
            p {
                padding-bottom: 2em;
            }

            font-size: $font-size-medium;
        }
    }

    .artist {
        display: flex;
        flex-direction: column;
        padding: 1em 0;

        &.withPhoto {
            @media #{$mq-large-screen} {
                width: 170%;
                max-width: 80vw;
                flex-direction: row;
                align-self: center;

                .artistInner {
                    width: 50%;
                }
            }
        }
    }

    .image {
        width: 50%;
        height: 100%;
        margin: 10% auto;
        @media #{$mq-large-screen} {
            width: 40%;
            margin-right: 10%;
        }
    }

    // .artistInner {
    //     @media #{$mq-medium-screen} {
    //         width: 50%;
    //     }
    // }

    .name {
        padding-bottom: 20px;
        border-bottom: 1px solid $color-black;
        font-size: $font-size-medium;
        font-weight: bold;
        @media #{$mq-medium-screen} {
            font-size: calc($font-size-medium + 4px);
        }
    }

    .role {
        font-style: italic;
        font-weight: normal;
        text-transform: lowercase;
    }

    .bio {
        padding-top: 20px;

        p {
            padding-bottom: 1em;
        }
    }

    .reference {
        padding-bottom: 20px;
        font-size: $font-size-small;
    }

    .linkContainer {
        display: flex;
        width: 100%;
    }

    .nextButton {
        margin-left: auto;
    }

    .linkButton {
        width: 200px;
        padding: 40px 30px;
        font-size: $font-size-small;
        @media #{$mq-medium-screen} {
            width: 250px;
            font-size: $font-size-normal;
        }
    }

    .circles {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
