@import '../commons/variables';
@import '../commons/placeholders';

.enter {
    z-index: 1;
    opacity: 0;
    transition: opacity 3s ease-out;
}

.enterActive {
    opacity: 1;
}

.exit {
    z-index: 2;
    opacity: 1;
    transition: opacity 3s ease-in;
}

.exitActive {
    opacity: 0;
}
