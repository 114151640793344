@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    padding: 10px;

    .items {
        display: flex;
        flex-direction: column;
    }

    .item {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .menuButton {
        position: fixed;
        top: 0;
        right: 0;
        width: 60px;
        padding: 10px;

        @media #{$mq-medium-screen} {
            width: 100px;
            padding: 20px;
        }
    }

    .button,
    .label,
    .languageButton {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        font-size: $font-size-large;
        font-weight: 300;
    }

    .languageButton {
        font-size: 1.5em;
    }

    .label {
        color: $color-green;
        font-family: $font-text;
        font-weight: 300;
    }

    .participants {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: $font-controls;

        & + .label:hover {
            font-style: italic;
        }
    }

    .participant {
        width: 50%;
        flex-grow: 1;
        font-size: $font-size-small;
        padding-bottom: 30px;
    }

    .participantButton,
    .storyButton {
        padding: 0.4em;
        color: $color-green;
        text-decoration: none;
        transition: transform 0.2s ease;

        &:focus {
            @extend %focus;
        }

        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    .participantButton {
        font-size: 1.2em;
        font-weight: bold;
    }

    .storyButton {
        padding: 0.2em;
        margin-left: 1em;
    }

    .horizontal {
        display: flex;
        flex-direction: row;

        .button {
            width: auto;
            padding: 0.75em 1em;
        }
    }

    @media #{$mq-medium-screen} {
        .label {
            font-size: $font-size-extra-large;
        }

        .participantsRow {
            width: 100%;
            flex-direction: row;
        }

        .participant {
            // width: 25%;
            font-size: $font-size-normal;
        }
    }
}
