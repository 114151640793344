@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    padding-top: 2em;
    background-color: $color-black;
    color: $color-green;
    font-size: 1em;
    overflow-y: scroll;

    .back {
        padding: 0;
        font-size: $font-size-normal;
    }

    .inner {
        max-width: 40em;
        padding: 1em 2em;
        margin: 0 auto;
        text-align: left;
    }

    .title {
        margin-bottom: 0.5em;
        font-size: 3em;
    }

    .text {
        line-height: 1.5;
        text-align: left;

        p {
            margin-bottom: 1em;
            font-family: $font-controls;
            font-weight: normal;
        }

        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }

        p:first-of-type {
            margin-bottom: 2em;
            font-weight: bold;
        }
    }

    @media #{$mq-medium-screen} {
        font-size: 1.2em;
        font-weight: 800;
    }

    @media #{$mq-large-screen} {
        .back {
            position: fixed;
            bottom: 20px;
            left: 20px;
        }
    }
}
