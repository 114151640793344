@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    display: flex;
    justify-content: center;

    .inner {
        @extend %hide-scrollbars;

        position: relative;
        display: flex;
        overflow: auto;
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .profile {
        position: absolute;
        top: 60px;
        padding-bottom: 200px;
        margin: 0 auto;
        vertical-align: top;

        @media #{$mq-medium-screen} {
            // padding-bottom: 150px;
        }
    }

    .lineContainer {
        position: absolute;
        top: 50%;
        width: 100%;
    }

    .line {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid rgba($color-white, 0.5);
    }

    .backContainer {
        position: absolute;
        z-index: 1;
        left: 0;
        padding: 15px;
    }

    .backButton {
        display: none;
        @media #{$mq-medium-screen} {
            display: none;
        }
    }

    .nextContainer {
        position: absolute;
        z-index: 1;
        right: 0;
        padding: 15px;
        @media #{$mq-medium-screen} {
            bottom: -4px;
            transform: translateY(33%);
        }
    }

    .nextButtonDesktop {
        display: none;

        @media #{$mq-medium-screen} {
            display: block;
        }
    }

    .nextButtonMobile {
        display: block;

        @media #{$mq-medium-screen} {
            display: none;
        }
    }

    // .bottomGradient {
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     height: 150px;
    //     background: linear-gradient(
    //         0deg,
    //         rgb(00 00 00 / 1) 0%,
    //         rgb(00 00 00 / 1) 50%,
    //         rgb(00 00 00 / 0) 100%
    //     );
    // }
}
