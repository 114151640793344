@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: block;
    border-radius: 0 0 0 10px;
    color: $color-white;
    opacity: 1;
    transition: opacity 0.5s ease-out;

    .icon {
        display: block;
        width: 100%;
        height: auto;
        transition: transform 0.2s ease;
    }

    &:focus {
        color: $color-black;
        @extend %focus;
    }

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}
