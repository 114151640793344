@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    background: transparent;
    border-radius: 10px;
    color: $color-green;
    text-align: left;
    text-decoration: none;

    .icon {
        width: auto;
        height: 2em;
        padding-right: 10px;
    }

    .children {
        position: relative;
        z-index: 1;
    }

    &:focus {
        @extend %focus;
    }
}
