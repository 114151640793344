@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    .inner {
        position: relative;
        display: flex;
        height: calc(100% - $controls-height);
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .outroButton {
        position: absolute;
        z-index: 1;
        bottom: 50%;
        transform: translate(0, 30vh);
    }

    .navigation {
        position: absolute;
        bottom: 0;
    }
}
