@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    height: 100px;
    padding: 0 10px;
    background: transparent;
    border-radius: 10px;
    color: $color-black;
    text-align: left;
    text-decoration: none;

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .icon {
        width: 25px;
        height: auto;
        margin-bottom: 20px;
        transition: transform 0.2s ease;
    }

    .shadow {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-green;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        box-shadow: 0 0 30px 30px $color-green;
        filter: blur(30px);
    }

    &:focus {
        @extend %focus;
    }

    &:hover,
    &:focus {
        .icon {
            transform: scale(1.1);
        }
    }
}
