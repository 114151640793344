@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    position: relative;
    display: flex;
    height: calc(100% - $controls-height);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .closedCaptions {
        width: 100%;
        color: $color-green;
    }

    .button {
        position: absolute;
        z-index: 1;
        bottom: 50%;
        transform: translate(0, 30vh);

        // border: 1px solid red;
    }
}
