@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    display: flex;
    height: calc(100% - $controls-height);
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
