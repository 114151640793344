@import '../commons/variables';
@import '../commons/placeholders';

.container {
    background: transparent;
    border-radius: 10px;
    color: $color-green;
    font-family: $font-text;
    text-align: left;
    text-decoration: none;

    &:focus {
        @extend %focus;
    }

    &:focus,
    &:hover {
        font-style: italic;
    }
}
