@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %hide-scrollbars;

    position: relative;
    display: none;
    overflow: visible;
    width: 100%;
    padding-bottom: 10px;
    font-size: $font-size-small;
    opacity: 0;
    overflow-y: scroll;

    &.visible {
        display: flex;
        opacity: 1;
    }

    .timeline {
        position: relative;
        width: 100%;
        padding-top: 75px;

        &.minWidth {
            min-width: $medium-screen;
        }
    }

    .handle {
        position: absolute;
        z-index: 10;
        left: 0;
        width: 0;
        height: 0;
        transition: left 1.5s ease-in-out;
    }

    .handleInner {
        position: absolute;
        top: 0;
        left: 0;
        width: calc($button-played-size / 2);
        height: calc($button-played-size / 2);
        background-color: $color-white;
        border-radius: 50%;
        transform: translate(-50%, 50%);
        transform-origin: left;
        transition: background-color 0.5s ease-in-out;
    }

    .items {
        position: relative;
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
    }

    .progress {
        position: absolute;
        top: 50%;
        right: calc($button-played-size / 2);
        left: calc($button-played-size / 2);
    }

    .item {
        position: relative;

        &:first-child {
            .progress {
                left: 0;
            }
        }

        &:last-child {
            .progress {
                right: 0;
            }
        }
    }

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .label {
        position: absolute;
        top: 0;
        left: 50%;
        display: flex;
        height: 0;
        transform: rotate(-45deg) translate(5px, 0);
        transform-origin: top left;
    }

    .labelTitle {
        transition: transform 0.2s ease-in-out;
    }

    .labelBackground {
        background-color: #000;
    }

    .line,
    .fadedLine {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid rgba($color-white, 0.4);
    }

    .fadedLine {
        z-index: 1;

        &.isWhite {
            border-color: rgba($color-white, 0.5);
        }
    }

    .controls {
        position: relative;
        display: flex;
        padding: 10px;
    }

    .iconButton {
        width: 50px;
        height: 100%;
        padding: 10px;
    }

    .button {
        position: relative;
        top: 0;
        display: block;
        width: $button-played-size;
        height: $button-played-size;
        padding: 0;
        transform: translate(-50%, 0);

        &:focus {
            border: 3px solid $color-green;
            box-shadow: 0 0 6px $color-green;

            .label {
                transform: rotate(-45deg) translate(10px, 0);
            }

            .labelTitle {
                transform: translate(5px, 0) scale(1.1);
                transform-origin: top center;
            }
        }
    }

    .enter {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(100%);
    }

    .enterActive {
        transform: translateX(0);
        transition: transform 1s ease-in;
    }

    .exit {
        transform: translateX(0);
    }

    .exitActive {
        transform: translateX(-100%);
        transition: transform 1s ease-in;
    }

    @media #{$mq-medium-screen} {
        overflow: visible;

        .handleInner {
            width: calc($button-size / 2);
            height: calc($button-size / 2);
        }

        .progress {
            right: calc($button-size / 2);
            left: calc($button-size / 2);
        }

        .button {
            width: $button-size;
            height: $button-size;
        }

        .played {
            .progress {
                left: calc($button-played-size / 2);
            }

            .button {
                top: calc(($button-size - $button-played-size) / 2);
                width: $button-played-size;
                height: $button-played-size;
            }

            .label {
                transform: rotate(-45deg) translate(5px, 0);
            }
        }

        .allPlayed {
            .button {
                transform: translate(-50%, -25%);
            }
        }

        .nextPlayed {
            .progress {
                right: calc($button-played-size / 2);
            }
        }
    }
}
