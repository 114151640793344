@import '../commons/variables';
@import '../commons/placeholders';

.container {
    padding: 2em;
    border: none;
    background: transparent;
    border-radius: 50%;
    color: $color-white;
    font-family: $font-controls;
    text-align: left;
    text-decoration: none;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        min-width: 2em;
        height: 2em;
        transform: translate(-50%, -50%);
        transition: transform 0.2s ease;
    }

    &:hover,
    &:focus {
        .icon {
            transform: translate(-50%, -50%) scale(1.1);
        }

        // background: $color-white;

        // color: $color-black;
    }
}
