@import 'variables';

%fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%focus {
    box-shadow: 0 0 12px $color-green;
    outline: 3px solid $color-green;
}

%hide-scrollbars {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}
