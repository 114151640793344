@import '../commons/variables';

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    color: $color-green;
    font-size: $font-size-normal;

    .inner {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .left {
        width: 100%;
        align-self: center;
        margin-bottom: 1em;
        font-size: $font-size-medium;
        text-align: center;
    }

    .name {
        margin: 0 auto;
    }

    .metadata {
        width: 100%;
        padding: 0 10%;
    }

    .metadataItem {
        display: flex;
        margin-bottom: 1.5em;
    }

    .name,
    .label,
    .value {
        width: fit-content;
        padding: 0.1em;
        background-color: #000;
    }

    .label {
        padding-bottom: 0.3em;

        // margin-bottom: 0.3em;
        font-size: $font-size-normal;
        font-style: italic;
    }

    .value {
        width: fit-content;

        // padding-bottom: 1.5em;

        margin-bottom: 1.3em;
        font-size: $font-size-normal;

        em {
            font-style: italic;
        }
    }

    .carouselMobile {
        position: relative;
        width: 50%;
        min-width: 250px;
        padding-bottom: 3em;
        margin: 0 auto;
    }

    .carouselDesktop {
        position: relative;
        display: none;
        width: 100%;
        margin: 0 auto;
    }

    .carouselShadow {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    @media #{$mq-medium-screen} {
        justify-content: center;

        .left {
            width: 50%;
            font-size: $font-size-extra-large;
        }

        .value {
            font-size: $font-size-medium;
        }
    }
    @media #{$mq-large-screen} {
        .inner {
            flex-direction: row;
            padding: 4em;
        }

        .left {
            padding-right: 2em;
        }

        .carouselMobile {
            display: none;
        }

        .carouselDesktop {
            display: block;
        }

        .metadata {
            width: 50%;
            padding: 0 2em;
        }
    }
}
