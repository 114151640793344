@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    @extend %fullscreen;

    .textureBackground {
        @extend %fullscreen;

        z-index: 20;
        background-image: url('../../images/texture_background.png');
        background-size: cover;
        mix-blend-mode: screen;
        pointer-events: none;
    }

    .noiseBackground {
        @extend %fullscreen;

        z-index: 20;
        background-image: url('../../images/noise_background_light.png');
        background-size: cover;
        mix-blend-mode: soft-light;
        pointer-events: none;
    }

    .gradientBottomBackground {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 210px;
        background: linear-gradient(
            0deg,
            rgb(00 00 00 / 1) 0%,
            rgb(00 00 00 / 1) 40%,
            rgb(00 00 00 / 0) 100%
        );
        pointer-events: none;
    }

    .logo {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 60px;
        padding: 10px;
        border-radius: 0 0 10px;
        transition: transform 0.2s ease;

        @media #{$mq-medium-screen} {
            width: 100px;
            padding: 20px;
        }

        &:focus {
            @extend %focus;
        }

        &:focus,
        &:hover {
            transform: scale(1.1);
        }
    }

    .navigation {
        position: absolute;
        bottom: $controls-mobile-height;
        width: 100%;

        @media #{$mq-medium-screen} {
            bottom: $controls-height;

            &.middle {
                bottom: 50%;
                padding-bottom: 100px;
                transform: translate(0, 50%);
                background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 1) 40%,
                    rgba(0, 0, 0, 1) 60%,
                    rgba(0, 0, 0, 0) 100%
                );
            }
        }
    }

    .controls {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .circles {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        min-width: $large-screen;
        transform: translate(-50%, -50%);
    }
}
