@import '../commons/variables';

.container {
    .hover {
        fill-opacity: 0;
        transition: fill-opacity 2s ease-out;
    }

    .normal {
        fill-opacity: 1;
        transition: fill-opacity 2s ease-out;
    }

    button:focus &,
    &:hover {
        .hover {
            fill-opacity: 1;
        }

        .normal {
            fill-opacity: 0;
        }
    }
}
