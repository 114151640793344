@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    height: 100px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    color: $color-green;
    text-align: left;
    text-decoration: none;

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .icon {
        width: 20px;
        height: auto;
        margin-bottom: 20px;
        transition: transform 0.4s ease;
    }

    // .shadow {
    //     position: absolute;
    //     z-index: 0;
    //     bottom: -10%;
    //     left: 0;
    //     width: 100%;
    //     height: 110%;
    //     background-color: $color-black;
    //     border-top-left-radius: 50%;
    //     border-top-right-radius: 50%;
    //     box-shadow: 0 0 30px 30px $color-black;
    //     filter: blur(30px);
    // }

    .shadow {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 50%;
        box-shadow: 0 0 100px 100px $color-black;
        transform: scaleX(1.5);
    }

    &:focus {
        box-shadow: 0 0 12px $color-black;
        outline: 3px solid $color-black;
    }

    &:hover,
    &:focus {
        .icon {
            transform: scale(1.1) rotate(-290deg);
        }
    }
}
