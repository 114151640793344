@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 1em;
    font-family: $font-controls;
    font-size: $font-size-small;
    word-wrap: none;

    .item {
        width: 100%;
        padding: 10px;

        &:disabled {
            opacity: 0.5;
        }
    }

    .controls {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .iconButton {
        min-width: 40px;
    }

    .audio {
        flex-grow: 1;
    }

    .navigation {
        width: 100%;
    }

    .options {
        .controls {
            justify-content: flex-end;
        }
    }

    .progress {
        width: 100%;
        height: 100%;
        appearance: none;
        background: transparent;
        cursor: pointer;

        // TRACK

        // Webkit
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 2px;
            background: $color-green;
            border-radius: 5px;
        }

        &:focus::-webkit-slider-runnable-track {
            outline: 1px solid $color-white;
        }

        // Firefox
        &::-moz-range-track {
            width: 100%;
            height: 2px;
            background: $color-green;
            border-radius: 5px;
        }

        &:focus::-moz-range-track {
            outline: 1px solid $color-white;
        }

        // THUMB

        &::-webkit-slider-thumb {
            width: 20px;
            height: 20px;
            border: none;
            appearance: none;
            background: $color-green;
            border-radius: 50%;
            transform: translateY(-50%) translateZ(0);
        }

        &:focus::-webkit-slider-thumb {
            border: 1px solid #707070;
            @extend %focus;
        }

        &::-moz-range-thumb {
            width: 20px;
            height: 20px;
            border: none;
            background: $color-green;
            border-radius: 50%;
        }

        &:focus::-moz-range-thumb {
            border: 3px solid rgb(112 112 112 / 1);
            @extend %focus;
        }
    }

    .timeline {
        flex-grow: 1;
        margin-right: 0.5em;

        .progress {
            width: 100%;
        }
    }

    .volume {
        .progress {
            width: 50px;

            &::-webkit-slider-thumb {
                width: 15px;
                height: 15px;
            }

            &::-moz-range-thumb {
                width: 15px;
                height: 15px;
            }
        }
    }

    .time {
        margin-right: 1em;
        color: $color-green;
        font-size: 0.85em;
        white-space: nowrap;

        span {
            display: inline-block;
            width: 0.6em;
            text-align: center;
        }
    }

    // &.noProgressBar {
    //     flex-direction: row;

    //     .navigation {
    //         display: none;
    //     }

    //     .audioControls {
    //         width: 100%;
    //     }

    //     .audioItem {
    //         &:first-child {
    //             flex-grow: 1;
    //         }
    //     }
    // }

    @media #{$mq-small-screen} {
        flex-direction: row;

        .audio {
            width: 100%;
            margin-right: 20px;
        }

        .options {
            width: auto;
        }

        .volume {
            .progress {
                width: 100px;
            }
        }
    }

    @media #{$mq-large-screen} {
        flex-direction: row;

        .navigation {
            width: 50%;
        }

        .options {
            .controls {
                justify-content: flex-end;
            }
        }
    }
}
