@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    position: fixed;
    overflow: hidden;

    .experience {
        @extend %fullscreen;
    }
}

.lock {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    min-width: $large-screen;
    padding: 20px;
    text-align: center;
    transform: translate(-50%, -50%);
}
