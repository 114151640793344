@import '../commons/variables';
@import '../commons/placeholders';

.container {
    @extend %fullscreen;

    display: flex;
    height: calc(100% - $controls-height);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
        width: 60%;
        padding: 20px;
        margin: 10px;
        background-color: #000;
        font-size: $font-size-medium;
        text-align: center;
    }

    .buttons {
        position: absolute;
        z-index: 1;
        bottom: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px;
    }

    @media #{$mq-medium-screen} {
        .text {
            font-size: $font-size-large;
        }

        .buttons {
            width: 60%;
            padding: auto;
        }
    }
}
