@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: flex;
    flex-direction: column;
    user-select: none;
}

.slideshow {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-grow: 1;
}

.arrow {
    position: absolute;
    bottom: -10px;
    display: block;
    border-radius: 2rem;
    transform-origin: center;

    :focus {
        @extend %focus;
    }
    @media #{$mq-medium-screen} {
        bottom: 10px;
    }
}

.arrowLeft {
    left: 15px;
    transform: rotate(180deg);

    @media #{$mq-medium-screen} {
        left: calc(50% - 100px);
    }
}

.arrowRight {
    right: 15px;
    @media #{$mq-medium-screen} {
        right: calc(50% - 100px);
    }
}

.frame {
    position: relative;
    height: 250px;
    flex-grow: 1;

    @media #{$mq-medium-screen} {
        height: 500px;
    }
}

.slides {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 20px;
    left: 0;
    overflow: hidden;
}

.slide {
    @extend %fullscreen;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;

    &.previous {
        transform: translate(-100%, 0);
    }

    &.current {
        transform: translate(0, 0);
    }

    &.next {
        transform: translate(100%, 0);
    }
}

.image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.liveRegion {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}
