@import '../commons/variables';
@import '../commons/placeholders';

.container {
    display: block;
    border-radius: 10px;
    color: $color-green;

    .icon {
        display: block;
        width: 40px;
        height: 40px;
        padding: 5px;
        transition: transform 0.2s ease;
    }

    &:focus {
        border-radius: 50%;
        @extend %focus;
    }

    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}
