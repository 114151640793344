@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;

    // padding: 60px;
    background: transparent;
    border-radius: 10px;
    color: $color-black;
    text-align: left;
    text-decoration: none;

    .iconContainer {
        height: 1em;
        flex-shrink: 0;
        padding-left: 2em;
        @media #{$mq-medium-screen} {
            padding-left: 3em;
        }
    }

    .icon {
        width: auto;
        height: 15px;
        @media #{$mq-medium-screen} {
            height: 20px;
        }
    }

    .shadow {
        position: absolute;
        z-index: -1;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: $color-green;
        border-radius: 50%;
        filter: blur(15px);
    }

    // .shadow {
    //     position: absolute;
    //     z-index: -1;
    //     box-shadow: 0 0 60px 100px $color-green;
    //     transform: scale(1.5, 0.5);
    // }

    .children {
        position: relative;
        z-index: 1;
    }

    .screenReaderOnly {
        position: absolute;
        top: auto;
        left: -10000px;
        overflow: hidden;
        width: 1px;
        height: 1px;
    }

    &:focus {
        box-shadow: 0 0 12px $color-black;
        outline: 3px solid $color-black;
    }
}
