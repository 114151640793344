@import '../commons/variables';
@import '../commons/placeholders';

$circle-size: 300px;

.container {
    @extend %fullscreen;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.faded {
        opacity: 0.6;
    }

    .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 50%;
        height: auto;
        transform-box: view-box;
        transform-origin: center center;

        // transition: transform 10s ease, color 10s ease;

        // will-change: transform;

        &.charactersActive {
            z-index: 2;
            color: $color-white;

            // transition: transform 5s ease, color 5s ease;
        }

        &.charactersPlayed {
            color: rgb(255 255 255 / 0);
        }

        &.outro {
            opacity: 0.2;
            transform: translate(-50%, -50%) scale(0.5);
        }
    }

    .name {
        z-index: 1;
        font-size: $font-size-normal;
        text-align: center;

        @media #{$mq-small-screen} {
            font-size: 2em;
        }
    }

    .selma {
        transform: translate(-50%, -50%);

        &.charactersActive {
            transform: translate(-125%, -75%);
        }

        &.charactersPlayed {
            transform: translate(-125%, -75%);
        }

        &.menu {
            transform: translate(-125%, -75%) rotate(-160deg);
        }

        &.text {
            opacity: 0;
            transform: translate(-50%, -70%);
        }

        // &.audio {
        //     transform: translate(-100%, -70%);
        // }

        // &.home {
        //     transform: translate(0%, 0%) rotate(10deg);
        // }

        // &.home {
        //     animation-duration: 20s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-selma;
        // }
    }

    .francois {
        transform: translate(-55%, -55%);

        &.charactersActive {
            transform: translate(-75%, -75%);
        }

        &.charactersPlayed {
            transform: translate(-75%, -75%);
        }

        &.menu {
            transform: translate(-75%, -75%) rotate(-200deg);
        }

        &.text {
            opacity: 0;
            transform: translate(-110%, -55%);
        }

        // &.audio {
        //     transform: translate(-105%, -55%);
        // }

        // &.home {
        //     transform: translate(-10%, 0%) rotate(45deg);
        // }

        // &.home {
        //     animation-duration: 25s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-francois;
        // }
    }

    .anne {
        transform: translate(-55%, -50%);

        &.charactersActive {
            transform: translate(-25%, -75%);
        }

        &.charactersPlayed {
            transform: translate(-25%, -75%);
        }

        &.menu {
            transform: translate(-25%, -75%) rotate(60deg);
        }

        &.text {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        // &.audio {
        //     transform: translate(-95%, -55%);
        // }

        &.home {
            transform: translate(-45%, -75%) rotate(45deg);
        }

        // &.home {
        //     animation-duration: 25s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-anne;
        // }
    }

    .josue {
        transform: translate(-40%, -55%);

        &.charactersActive {
            transform: translate(25%, -75%);
        }

        &.charactersPlayed {
            transform: translate(25%, -75%);
        }

        &.menu {
            transform: translate(25%, -75%) rotate(160deg);
        }

        &.text {
            opacity: 0.5;
            transform: translate(-50%, -40%);
        }

        // &.audio {
        //     transform: translate(-100%, -40%);
        // }

        // &.home {
        //     transform: translate(-75%, -75%) rotate(45deg);
        // }

        // &.home {
        //     animation-duration: 30s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-josue;
        //     animation-timing-function: ease;
        // }
    }

    .mariechristine {
        transform: translate(-55%, -40%);

        &.charactersActive {
            transform: translate(-125%, -25%);
        }

        &.charactersPlayed {
            transform: translate(-125%, -25%);
        }

        &.menu {
            transform: translate(-125%, -25%) rotate(-160deg);
        }

        &.text {
            opacity: 0.5;
            transform: translate(-50%, -70%);
        }

        // &.audio {
        //     transform: translate(0%, -70%);
        // }

        // &.home {
        //     transform: translate(-50%, -25%) rotate(45deg);
        // }

        // &.home {
        //     animation-duration: 30s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-mariechristine;
        //     animation-timing-function: ease;
        // }
    }

    .nathalie {
        transform: translate(-50%, -40%);

        &.charactersActive {
            transform: translate(-75%, -25%);
        }

        &.charactersPlayed {
            transform: translate(-75%, -25%);
        }

        &.menu {
            transform: translate(-75%, -25%) rotate(-180deg);
        }

        &.text {
            opacity: 0.5;
            transform: translate(-100%, -50%);
        }

        // &.audio {
        //     transform: translate(0%, -55%);
        // }

        // &.home {
        //     transform: translate(-100%, -60%) rotate(45deg);
        // }

        // &.home {
        //     animation-duration: 20s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-nathalie;
        // }
    }

    .sherri {
        transform: translate(-40%, -55%);

        &.charactersActive {
            transform: translate(-25%, -25%);
        }

        &.charactersPlayed {
            transform: translate(-25%, -25%);
        }

        &.menu {
            transform: translate(-25%, -25%) rotate(100deg);
        }

        &.text {
            opacity: 0;
            transform: translate(0%, -55%);
        }

        // &.audio {
        //     transform: translate(-5%, -50%);
        // }

        // &.home {
        //     transform: translate(-75%, -60%) rotate(45deg);
        // }

        // &.home {
        //     animation-duration: 30s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-sherri;
        //     animation-timing-function: ease;
        // }
    }

    .stephane {
        transform: translate(-40%, -40%);

        &.charactersActive {
            transform: translate(25%, -25%);
        }

        &.charactersPlayed {
            transform: translate(25%, -25%);
        }

        &.menu {
            transform: translate(25%, -25%) rotate(160deg);
        }

        &.text {
            opacity: 0.5;
            transform: translate(0%, -50%);
        }

        // &.audio {
        //     transform: translate(0%, -40%);
        // }

        // &.home {
        //     transform: translate(-60%, -30%) rotate(-20deg);
        // }

        // &.home {
        //     animation-duration: 20s;
        //     animation-fill-mode: forwards;
        //     animation-iteration-count: infinite;
        //     animation-name: home-stephane;
        // }
    }
}

@media #{$mq-medium-screen} {
    .circle {
        width: 500px;
        height: 500px;
    }
}

// @keyframes home-selma {
//     0% {
//         transform: translate(0%, 0%) rotate(10deg);
//     }

//     50% {
//         transform: translate(-10%, -30%) rotate(20deg);
//     }

//     100% {
//         transform: translate(0%, 0%) rotate(10deg);
//     }
// }
// @keyframes home-francois {
//     0% {
//         transform: translate(-10%, 0%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-10%, -30%) rotate(0deg);
//     }

//     100% {
//         transform: translate(-10%, 0%) rotate(45deg);
//     }
// }
// @keyframes home-anne {
//     0% {
//         transform: translate(10%, -75%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-45%, -75%) rotate(45deg);
//     }

//     100% {
//         transform: translate(10%, -75%) rotate(45deg);
//     }
// }
// @keyframes home-josue {
//     0% {
//         transform: translate(0%, 0%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-75%, -75%) rotate(45deg);
//     }

//     100% {
//         transform: translate(0%, 0%) rotate(45deg);
//     }
// }
// @keyframes home-mariechristine {
//     0% {
//         transform: translate(-100%, 0%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-50%, -25%) rotate(45deg);
//     }

//     100% {
//         transform: translate(-100%, 0%) rotate(45deg);
//     }
// }
// @keyframes home-nathalie {
//     0% {
//         transform: translate(-100%, -60%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-50%, -60%) rotate(45deg);
//     }

//     100% {
//         transform: translate(-100%, -60%) rotate(45deg);
//     }
// }
// @keyframes home-sherri {
//     0% {
//         transform: translate(-100%, -70%) rotate(45deg);
//     }

//     50% {
//         transform: translate(-75%, -60%) rotate(45deg);
//     }

//     100% {
//         transform: translate(-100%, -70%) rotate(45deg);
//     }
// }
// @keyframes home-stephane {
//     0% {
//         transform: translate(-40%, -40%) rotate(10deg);
//     }

//     50% {
//         transform: translate(-60%, -30%) rotate(-20deg);
//     }

//     100% {
//         transform: translate(-40%, -40%) rotate(10deg);
//     }
// }
