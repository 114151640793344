@import '../commons/variables';
@import '../commons/placeholders';

.container {
    position: relative;
    min-height: 100vh;
}

.content {
    position: relative;
    z-index: 1;
    min-height: 100vh;
}

.navigation {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;

    .menuOpened & {
        position: fixed;
    }
}

.navShadow {
    position: absolute;
    z-index: -1;
    top: 0%;
    left: -10%;
    width: 110%;
    height: 80%;
    background-color: $color-black;
    border-radius: 50%;
    box-shadow: 0 0 30px 30px $color-black;
    filter: blur(30px);
    opacity: 0.9;
}

// .navShadow {
//     position: absolute;
//     z-index: -1;
//     top: 50%;
//     left: 50%;
//     box-shadow: 0 0 80px 50px $color-black;
//     transform: scale(1.5, 0.5);

//     @media #{$mq-medium-screen} {
//         box-shadow: 0 0 120px 100px $color-black;
//     }
// }

.menuButton {
    width: 60px;
    padding: 10px;

    @media #{$mq-medium-screen} {
        width: 100px;
        padding: 20px;
    }
}

.hidden {
    position: absolute;
    top: 0;
    right: 100%;
    display: flex;
    flex-direction: column;
}

.languageButton {
    padding: 10px;
    color: $color-green;
    font-size: $font-size-small;
    text-decoration: none;
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.2);
    }

    &:focus {
        @extend %focus;
    }

    @media #{$mq-medium-screen} {
        padding: 20px;
    }
}

.menuContainer {
    @extend %fullscreen;

    position: fixed;
    z-index: 2;
    display: flex;
    overflow: auto;
    background: rgba(#000, 0.85);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-out;

    .inner {
        display: flex;
        flex-direction: column;
        // @extend %fullscreen;
        margin: auto;
    }

    .menuOpened & {
        opacity: 1;
        pointer-events: all;
    }
}

.menu {
    width: 100%;
    max-width: 800px;
    margin: auto;
}
