/**
 * Colors
 */
$color-white: #e2e8e6;
$color-black: #212121;
$color-green: #d6fd97;

/**
  * Fonts
  */
// $font-text: 'Inter', sans-serif;
$font-text: 'le-monde-livre-classic-byol', serif;
$font-title: 'Inter', sans-serif;
$font-controls: 'Inter', sans-serif;
$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-medium: 24px;
$font-size-large: 36px;
$font-size-extra-large: 46px;

/**
  * Screen size
  */
$small-screen: 500px;
$medium-screen: 768px;
$large-screen: 1100px;
$larger-screen: 1400px;

/**
  * Media queries
  */
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-larger-screen: 'only screen and (min-width: #{$larger-screen})';

/**
  * Controls
  */
$controls-height: 60px;
$controls-mobile-height: 100px;

/**
  * Navigation
  */
$button-size: 60px;
$button-played-size: 40px;
